import React from 'react';
import CountUp from 'react-countup';
import misc from '../misc.json';
import {Helmet} from 'react-helmet';

const About = (props) => {
    // props.location.toggleSidebar();

    return (
        <div>
            <Helmet>
                <title>Մեր Մասին ֊ Տպարան․am</title>
                <meta name="description" content={misc['meta']['description'] + " Տպարան․am «Տպարան.ԷյԷմ» ՍՊԸ֊ն հիմնադրվել է 2014 թ-ին: Հինգ տարիների ընթացքում կազմակերպությունը գրանցել է դինամիկ աճ և համագործակցում է շուրջ 400 առաջատար կազմակերպությունների հետ: Տպագրություն, Հրատարակչություն և Դիզայն: Գունավոր թվային/լազերային և օֆսեթ տպագրություն. Գովազդային նյութերի պատրաստում: Տպագրություն շապիկների և բաժակներ վրա: Բուկլետներ, գրքեր, վիզիտկա, դիզայն, ժուրնալ, ամսագրեր, գունաբաժանում, կազմարարական աշխատանքներ, տպագրատուն, տպարան, տպագրություն, պատի օրացույց, սեղանի օրացույց, tpagrutyun, tpagrutsyun, tparan, oracuyc, pati oracuyc, seghani oracuyc"}/>
                <meta name="keywords" content={misc['meta']['keywords']}/>
            </Helmet>
            <div className="fh5co-narrow-content">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="fh5co-heading animate-box" data-animate-effect="fadeInLeft">ՄԵՐ <span> ՄԱՍԻՆ</span></h2>
                        <p>
                        «Տպարան.ԷյԷմ» ՍՊԸ֊ն հիմնադրվել է 2014 թ-ին:
                        Տասը տարիների ընթացքում կազմակերպությունը գրանցել է դինամիկ աճ և համագործակցում է ավելի քան 1000 առաջատար կազմակերպությունների հետ:
                        Հիմնադրման օրվանից ընկերության հիմնական ուղղությունն է եղել օֆսեթ տպագրությունը:
                        Ընկերությունը համագործակցելով աշխարհահռչակ գերմանական HEIDELBERG ընկերության հետ` վերջինիս պաշտոնական ներկայացուցիչ` HEIDELBERG SWITZERLAND ընկերությունից, 2022թ. ձեռք է բերել նորագույն 4 գույնանի տպագրական մեքենա, որի արդյունքում պատվերներն ունենում են գերմանական բարձր որակ և կատարվում են շվեյցարական ճշգրտությամբ՝ ճիշտ նշված ժամկետներում:
                        Շուկայի պահանջարկին համընթաց՝ այսօր ընկերությունը կատարում է նաև թվային, լազերային, մաղային, լայնաֆորմատ և սուբլիմացիոն և UV տպագրություն:
				        </p>
                    </div>
                    <div className="col-md-6 col-md-push-1 animate-box" data-animate-effect="fadeInLeft">
                    </div>
                </div>

            </div>
            <div className="fh5co-counters header-white">
                <div className="fh5co-narrow-content">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" style={{color: "dimgray"}} end={misc['services']} duration={2} delay={0} />
                            <span className="fh5co-counter-label" style={{color: "dimgray"}}>ԱՌԱՋԱՐԿՎՈՂ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ</span>
                        </div>
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" style={{color: "dimgray"}} end={misc['clients']} duration={2} delay={0} />
                            <span className="fh5co-counter-label" style={{color: "dimgray"}}>ԳՈՐԾԸՆԿԵՐՆԵՐ</span>
                        </div>
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" style={{color: "dimgray"}} end={misc['deals']} duration={2} delay={0} />
                            <span className="fh5co-counter-label" style={{color: "dimgray"}}>ԳՈՐԾԱՐՔՆԵՐ</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default About;