import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { motion, useDomEvent } from "framer-motion";

const transition = {
  type: "spring",
  damping: 25,
  stiffness: 120
};

export const Image = ({ src, alt }) => {
  const [isOpen, setOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);
  const imgRef = useRef(null);

  useDomEvent(containerRef, "scroll", () => isOpen && setOpen(false));

  useEffect(() => {
    if (isOpen && imgRef.current) {
      const imgWidth = imgRef.current.offsetWidth;
      const imgHeight = imgRef.current.offsetHeight;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const scaleWidth = viewportWidth / imgWidth * 0.9;
      const scaleHeight = viewportHeight / imgHeight * 0.9;

      setScale(Math.min(scaleWidth, scaleHeight));
    } else {
      setScale(1);
    }
  }, [isOpen]);


  return (
    <div ref={containerRef} className={`image-container ${isOpen ? "open" : ""}`}>
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        className="shade"
        onClick={() => setOpen(false)}
      />
      <motion.img
        ref={imgRef}
        src={src}
        alt={alt}
        onClick={() => setOpen(!isOpen)}
        layoutTransition={transition}
        initial={{ scale: 1 }}
        animate={{ scale }}
        style={{ transformOrigin: "center center" }}
      />
    </div>
  );
};